import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Header from "../../components/ContactPage/Header"
import OfficeComponent from "../../components/ContactPage/OfficeComponent"
import MapComponent from "../../components/ContactPage/MapComponent"
import { useStaticQuery, graphql } from "gatsby"
import { useIntl, navigate } from "gatsby-plugin-react-intl"
import TextComponent from "../../components/ContactPage/TextComponent"
import image1 from "../../images/niepolomice-image1.jpg"
import image2 from "../../images/niepolomice-image2.jpg"
import SecondSection from "../../components/ContactPage/SecondSection"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const ImageBox = styled.div`
  width: 100%;
  aspect-ratio: 16/8;
  display: flex;
  justify-content: center;
  margin-bottom: 78px;

  img {
    width: 100%;
    height: 100%;
  }
`
const H3Box = styled.div`
  h3 {
    margin-bottom: 10px;

    font-size: 2.2rem;
    font-weight: 700;
    line-height: 3.3rem;
    letter-spacing: 0.04em;

    background: linear-gradient(
      90.75deg,
      #bb913c -4.54%,
      #eae69c 43.88%,
      #ce9638 101.11%
    );

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`

const ContactPage = () => {
  const intl = useIntl()
  const lang = intl.locale
  const [data, setData] = useState({})
  const isBrowser = typeof window !== "undefined"

  const query = useStaticQuery(graphql`
    query ContactNC {
      polish: contentfulContact(node_locale: { eq: "pl-PL" }) {
        location
        office_niepolomice
        debosz_nieruchomosci {
          raw
        }
        opening_hours {
          raw
        }
        rynek_12_3 {
          raw
        }
        text1 {
          raw
        }
        text2 {
          raw
        }
        text3 {
          raw
        }
        text4 {
          raw
        }
        text5 {
          raw
        }
        text6
        text7 {
          raw
        }
        text8 {
          raw
        }
        header
      }
      english: contentfulContact(node_locale: { eq: "en-US" }) {
        location
        office_niepolomice
        debosz_nieruchomosci {
          raw
        }
        opening_hours {
          raw
        }
        rynek_12_3 {
          raw
        }
        text1 {
          raw
        }
        text2 {
          raw
        }
        text3 {
          raw
        }
        text4 {
          raw
        }
        text5 {
          raw
        }
        text6
        text7 {
          raw
        }
        text8 {
          raw
        }
        header
      }
    }
  `)

  useEffect(() => {
    if (lang === "en") {
      navigate("/contact/niepolomice/")
    }
  }, [lang])

  useEffect(() => {
    if (isBrowser) {
      if (intl.locale === "en") {
        setData(query.english)
      }
      if (intl.locale === "pl") {
        setData(query.polish)
      }
    }
  }, [intl])

  return (
    <Layout>
      <Seo
        title="Dębosz - Biuro Nieruchomości Niepołomice"
        renderSchemaLd={true}
        description="Odwiedź nasze biuro w Niepołomicach. Nasze biuro współpracuje z najlepszymi Agentami nieruchomości!"
      />
      <Header contact_niepolomice={data.header} goldHeader={false} />
      <OfficeComponent
        office_niepolomice={data.office_niepolomice}
        debosz_nieruchomosci={data.debosz_nieruchomosci}
        rynek={data.rynek_12_3}
        opening_hours={data.opening_hours}
      />
      <MapComponent location={data.location} />
      <TextComponent> {data.text1 && renderRichText(data.text1)}</TextComponent>
      <TextComponent>{data.text2 && renderRichText(data.text2)}</TextComponent>
      <ImageBox>
        <img src={image1} alt="image" />
      </ImageBox>
      <TextComponent>{data.text3 && renderRichText(data.text3)}</TextComponent>
      <TextComponent>{data.text4 && renderRichText(data.text4)}</TextComponent>

      <SecondSection image={image2}>
        {data.text5 && renderRichText(data.text5)}
      </SecondSection>

      <TextComponent>{data.text7 && renderRichText(data.text7)}</TextComponent>
      <TextComponent>{data.text8 && renderRichText(data.text8)}</TextComponent>
      <TextComponent>
        <H3Box>
          <h3>{data.text6}</h3>
        </H3Box>
      </TextComponent>
    </Layout>
  )
}

export default ContactPage
